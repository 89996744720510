import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Pagetitle from "../components/Pagetitle";
import Bodytext from "../components/Bodytext";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

export default () => (
  <div className="scroll-content">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Oops! Wrong page!</title>
    </Helmet>
    <GatsbySeo noindex={true} />
    <div className="page">
      <Header />
      <div className="page--404 container">
        <Pagetitle pageTitle="Oops! Wrong page!" />
        <Bodytext bodyText="The page you are looking for doesn't exist or an other error occurred." />
      </div>
      <Footer />
    </div>
  </div>
)
